import { template as template_e40aaa70c09b40529ce3e1f7d01a5489 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e40aaa70c09b40529ce3e1f7d01a5489(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
