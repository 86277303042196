import { template as template_40b1d789f53344d9a1428c2f9c04870e } from "@ember/template-compiler";
const FKLabel = template_40b1d789f53344d9a1428c2f9c04870e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
