import { template as template_0157204f5d54467597392049a08e1419 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0157204f5d54467597392049a08e1419(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
